<template>
  <div class="index_container instruction">
    <div class="instruction_main">
      <img
        class="instruction_img"
        :src="require('@/assets/images/instruction.jpg')"
        alt=""
      />
      <div class="instruction_btn">
        <CustomButton class="btn" size="small" @click="downLoad('流程简介')"
          >流程简介</CustomButton
        >
        <!-- <CustomButton class="btn" size="small" @click="downLoad('jz')"
          >讲者操作手册下载</CustomButton
        >
        <CustomButton class="btn" size="small" @click="downLoad('tl')"
          >讨论嘉宾操作手册下载</CustomButton
        > -->
        <CustomButton class="btn" size="small" @click="downLoad('会议邀请函')"
          >会议邀请函下载</CustomButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";

export default {
  name: "instruction",
  components: {
    CustomButton,
  },
  data() {
    return {
      data: [],

      // roleType: {
      //   zx: "主席操作手册",
      //   jz: "讲者操作手册",
      //   tl: "讨论嘉宾操作手册",
      // },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$axios
        .get(this.$urls.live.controlbook)
        .then((res) => {
          this.data = res.data;
        })
        .catch(() => {});
    },
    downLoad(type) {
      let result = this.data.filter((v) => v.file_name == type);
      window.open(result[0].link);
    },
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
}
.instruction_main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.instruction_img {
  width: 100%;
  height: auto;
}
.instruction_btn {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe7f4;
  flex-direction: column;
}
.instruction_btn .btn {
  width: 60%;
  padding: 1rem;
  font-size: 1rem;
  margin: 1rem;
}
</style>